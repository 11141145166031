import { CreateFormReleaseUseCase, CreateFormUseCase, DeleteFormUseCase, FindFormByIdUseCase, UpdateFormUseCase } from './form';
import { CreateFolderUseCase, DeleteFolderUseCase, UpdateFolderUseCase } from './folder';
import { CreateSectionUseCase, UpdateSectionUseCase, DeleteSectionUseCase } from './section';
import { UpdateQuestionUseCase, DeleteQuestionUseCase } from './question';
import { UpdateFillInBlankCorrectAnswerUseCase } from './correct-answer';
import { WorkspaceSearchUseCase } from './workspace';
import { SearchSpaceObjectsUseCase } from './space';
import { GetGradesUseCase } from './grade';
import { GetSubjectsUseCase } from './subject';
import { GetGradesAndSubjectsUseCase } from './get-grades-and-subjects';
import { CreateFillInBlankAnswerUseCase, UpdateFillInBlankAnswerUseCase } from '@module/assignment';
import { UpdateSubQuestionUseCase } from './sub-question';
import { GetGlobalFormsUseCase } from './global-form';
export * from './form';
export * from './folder';
export * from './section';
export * from './question';
export * from './answer';
export * from './workspace';
export * from './correct-answer';
export * from './grade';
export * from './subject';
export * from './get-grades-and-subjects';
export * from './space';
export * from './file';
export * from './sub-question';
export * from './global-form';
export var formUseCases = [CreateFormUseCase, DeleteFormUseCase, FindFormByIdUseCase, UpdateFormUseCase, CreateFolderUseCase, DeleteFolderUseCase, UpdateFolderUseCase, CreateSectionUseCase, UpdateSectionUseCase, DeleteSectionUseCase, UpdateQuestionUseCase, DeleteQuestionUseCase, CreateFillInBlankAnswerUseCase, WorkspaceSearchUseCase, CreateFillInBlankAnswerUseCase, UpdateFillInBlankAnswerUseCase, UpdateFillInBlankCorrectAnswerUseCase, GetGradesUseCase, GetSubjectsUseCase, GetGradesAndSubjectsUseCase, SearchSpaceObjectsUseCase, CreateFormReleaseUseCase, UpdateSubQuestionUseCase, GetGlobalFormsUseCase];